body {
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  background: #313131;
  color: #f9f9f9;
  margin-top: 30px;
}

a, a:visited, a:active {
  color: #2196F3;
}

.section {
  padding: 2rem 2rem;
}
.section.chart1 {
  background-color: #491d17;
}
.section.chart2 {
  background-color: #041828;
}

h1.heading {
  font-size: 3.0rem;
  margin-bottom: 1rem;
  margin-top: 0;
}

h2.total {
  margin: 0 0 0.5rem 0;
  font-size: 5.0rem;
  font-family: 'Kanit', sans-serif;
  color: #fff;
}

h3 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
}
div.help {
  font-size: 1.0rem;
  color: #ddd;
  font-style: italic;
}

h6 {
  font-size: 0.8rem;
  font-weight: normal;
  color: #bbb;
  margin: 0;
}

.donate-form {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.alert {
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 1rem;
}

.twitter {
  margin-top: 1rem;
}

#chartdiv {
  width: 100%;
  height: 500px;
}

hr {
  margin: 2rem 0;
}

.footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.totals span {
  display: block;
  margin-bottom: 0.5rem;
}

.last-updated, .pack-ev {
  margin-top: 1rem;
}

@media only screen and (min-width: 575px) {
  .totals span {
    display: inline-block;
    margin: 0 1rem;
  }
}
